@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  font-size: 1rem;
  padding-right: 0 !important;
}

.image-carousel {
  height: calc(100vh - 80px);
}

.input_error {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}

input:invalid[focused="true"] {
  border-bottom-color: red;
}
.secondary-input input:invalid[focused="true"] {
  border: 1px solid red;
}
input:invalid[focused="true"] ~ label {
  color: red;
}

input:invalid[focused="true"] ~ span {
  display: block;
}
.menu {
  position: fixed;
  left: -100vw;
  top: 0;
  visibility: hidden;
  transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1) 0s;
  background-color: #fff;
  background-image: url("../src/assets/nav_background.webp");
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 100%;
}
.image-class {
  aspect-ratio: 128 / 128;
}

#my-tooltip {
  z-index: 1000;
}

.navOpen .menu {
  left: 0;
  visibility: visible;
}
.banner-container img {
  min-height: 12rem;
  object-fit: cover;
}

.rev-container {
  background-image: url(../src/assets/reviews/reviewsBackground.webp);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  padding: 4rem 0;
}
.productTop,
.productBottom {
  background: #c0c0c0;
  background-size: cover;
  transition: background 0.5s ease;
  opacity: 1;
  height: 15rem;
  width: 15rem;
}

.productTop:hover,
.productTop:active,
.productTop:focus {
  background: #ddd9d9;
}
.productTop:after,
.productBottom:after {
  display: block;
  background-color: #e4e4e4;
  border-radius: 100%;
  z-index: -1;
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 18rem;
  width: 18rem;
  -webkit-transition: opacity 500ms ease;
  -moz-transition: opacity 500ms ease;
  -o-transition: opacity 500ms ease;
  transition: opacity 500ms ease;
}

.urbanSection {
  background-image: url("assets/newProduct.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.custom-tile-layer .leaflet-tile {
  filter: grayscale(100%);
}
.leaflet-container a,
.leaflet-container a:visited {
  color: #86c559;
  display: block;
}

.leaflet-container a:hover,
.leaflet-container a:active,
.leaflet-container a:focus {
  color: #6dab4c;
  transition: all 0.3s ease-in;
}

.leaflet-container a.popup-address,
.leaflet-container a.popup-address:visited {
  color: #000000db;
  text-decoration: underline;
  display: block;
  transition: all 0.3s ease-in;
}

.leaflet-container a.popup-address:hover,
.leaflet-container a.popup-address:active,
.leaflet-container a.popup-address:focus {
  color: #6dab4c;
}
.leaflet-control-attribution {
  display: none;
}
.swiper-wrapper {
  align-items: center;
}
.swiper-button-prev,
.swiper-button-next {
  padding: 2rem;
  background: rgb(134, 197, 89, 0.8);
  border-radius: 50%;
  color: #5c5e5c !important;

  transition: all 0.15s ease-in;
}
.swiper-button-prev:hover,
.swiper-button-prev:focus,
.swiper-button-prev:active,
.swiper-button-next:hover,
.swiper-button-next:focus,
.swiper-button-next:active {
  background: rgba(109, 171, 76, 0.9);
}
.reviews-container .swiper-button-prev:after,
.reviews-container .swiper-button-next:after {
  font-weight: 900;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 24px;
  font-weight: 900;
}

.reviews-container .swiper-button-prev,
.reviews-container .swiper-button-next {
  width: 1rem;
  height: 1rem;
  padding: 1rem;
}
.reviews-container .swiper-button-prev {
  left: 0;
}
.reviews-container .swiper-button-next {
  right: 0;
}
.reviews-container .swiper-button-prev:after,
.reviews-container .swiper-button-next:after {
  font-size: 0.75rem;
}
footer {
  background-color: #ebebeb;
}
.apostro::after,
.apostro:after {
  font-family: cursive !important;
}
.feedback-item:before {
  content: "";
  height: 24px;
  width: 24px;
  background: url("../src/assets/quotationmarks.png") no-repeat;
  position: absolute;
  top: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.rotateY-180 {
  transform: rotateY(180deg);
}
.parent-nav {
  position: relative;
}
.child-nav {
  max-height: 0px;
  min-height: 260px;
  opacity: 0;
  transition: 0.3s ease-in-out;
  position: absolute;
  right: -100%;
  background-color: #fff;
  z-index: 100;
}
.parent-nav:hover .child-nav,
.parent-nav:focus .child-nav,
.parent-nav:active .child-nav {
  max-height: 1000px;
  opacity: 1;
  right: 0;
}
.skeleton-loader {
  background-color: #ddd; /* Light grey background */
  animation: shimmer 1.5s infinite linear;
}

.database-table {
  min-height: 280px;
}
.image-modal-database {
  min-height: 320px;
  min-width: 320px;
}
/* Paginator Start */
ul[role="navigation"] {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

ul[role="navigation"] li {
  margin: 0 5px;
}

ul[role="navigation"] li a {
  display: block;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  transition: all 0.3s ease;
}

ul[role="navigation"] li a:hover {
  background-color: #f0f0f0;
  border-color: #aaa;
}

ul[role="navigation"] li.selected a {
  background-color: #86c559;
  color: white;
  border-color: #86c559;
  cursor: default;
}

ul[role="navigation"] li.previous.disabled a,
ul[role="navigation"] li.next.disabled a {
  color: #bbb;
  cursor: not-allowed;
  background-color: #f9f9f9;
  border-color: #ddd;
}

ul[role="navigation"] li.previous a,
ul[role="navigation"] li.next a {
  font-weight: 600;
}

ul[role="navigation"] li a[aria-disabled="true"] {
  pointer-events: none;
}

ul[role="navigation"] li a[aria-current="page"] {
  cursor: default;
  font-weight: bold;
}

/* Paginator END */

@keyframes shimmer {
  0% {
    background-color: #eee; /* Lighter shade */
  }
  50% {
    background-color: #ddd; /* Original color */
  }
  100% {
    background-color: #eee; /* Lighter shade */
  }
}
@media only screen and (max-width: 1536px) {
  .image-carousel {
    height: 600px;
  }
  .menu {
    background-image: unset;
  }

  .child-nav {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .image-carousel {
    height: 400px;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 18px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    padding: 1.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .image-carousel {
    height: 350px;
  }

  .child-nav {
    max-height: unset;
    opacity: unset;
  }
  .parent-nav:hover .child-nav,
  .parent-nav:focus .child-nav,
  .parent-nav:active .child-nav {
    max-height: unset;
    opacity: unset;
  }

  .table-pagination li:not(.selected, .next, .previous, .break) {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .image-carousel {
    height: 250px;
  }
}

/* For mobile screens and below */
@media (max-width: 640px) {
  .video-container {
    width: 100%; /* Adjust the width to your desired value for mobile */
    padding-top: 56.25%; /* Maintain the aspect ratio (16:9) for the video */
    position: relative;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .reviews-container .swiper-button-prev {
    left: 0.5rem;
  }
  .reviews-container .swiper-button-next {
    right: 0.5rem;
  }
  .paginator {
    display: none;
  }
}

/* For larger screens */
@media (min-width: 641px) {
  .video-container {
    width: 540px; /* Set the default width for larger screens */
    height: 310px; /* Set the default height for larger screens */
  }
}

@media (min-width: 640px) {
  .image-class {
    aspect-ratio: 96 / 96;
  }
}

@media (min-width: 1024px) {
  .image-class {
    aspect-ratio: 112 / 112;
  }
}

@media (min-width: 1536px) {
  .image-class {
    aspect-ratio: 128 / 128;
  }
}
